<template>
  <div>
    <div v-if="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Extração de dados</span>
          </div>
          <div class="right"></div>
        </div>
        <!-- <div class="wrapper__card__body">Tela de extração de dados</div> -->
        <div class="wrapper__card__body">
          <div class="wrapper__card__body__left">
            <div class="card-key">
              <strong>Sua chave de acesso:</strong>

              <div class="card-key__line mt-2">
                <span> {{ token || "-" }} </span>
                <img src="/icons/copy-icon.svg" />
              </div>
            </div>

            <div class="card-resource">
              <span class="title-item"> Resource URL </span>
              <span class="link-resource">
                https://datatoact-back.eye.zooxsmart.com/api/exportData
              </span>
            </div>

            <div class="card-parameters">
              <span class="title-item">Parâmetros</span>
              <div class="card-parameters__table">
                <z-table class="table" :headers="headers" :items="tableItems">
                </z-table>
              </div>
            </div>
          </div>

          <div class="wrapper__card__body__right">
            <div class="how-test">
              <span class="title-item">Como testar</span>
              <p>
                Para extrair dados da API é necessário utilizar algum cliente
                HTTP, seja gráfico (Postman, Insomnia...) ou programaticamente
                usando tecnoligias como CURL, Node.js e Python.
              </p>
            </div>

            <div class="tabs">
              <z-tabs ref="tabs" android :tabs="tabs">
                <template #terceira>
                  <CodeHighlight
                    v-if="objectMounted != null"
                    language="javascript"
                  >
                    <pre>
  <code>
  curl --location 'https://datatoact-back.eye.zooxsmart.com/api/exportData' \
  --header 'Content-Type: application/json' \
  --header 'token: {{ token }}' \
  --data-raw '
       {{objectMounted}}
  '
  </code>
                      </pre>
                  </CodeHighlight>
                </template>
              </z-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading v-if="!errorOnFetchData" />
        <div v-else>
          <span>Não foi possivel buscar as informações solicitadas</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

import moment from "moment";
import ApiClient from "@/services/ApiClient";
export default {
  components: {
    CodeHighlight,
  },
  data() {
    return {
      errorOnFetchData: false,
      connectionId: "",
      connectionFromPage: "",
      objectMounted: {
        start_date: "2024-04-09 00:00:00",
        end_date: "2024-04-15 23:59:00",
        email: "test@zooxsmart.com.br",
        nome_template: "zoox_template",
        celular: "21999999999",
        tipo_envio: "sms",
        evento: "send",
        page: 1,
      },
      showInformations: false,
      token: "",
      tableResponse: null,
      http: new ApiClient(),
      headers: [
        { text: "NOME DA VARIÁVEL", value: "name", sortable: true },
        { text: "TIPO", value: "type", sortable: true },
        { text: "VALORES", value: "values", sortable: true },
      ],
      tableItems: [
        {
          name: "start_date",
          type: "Data",
          values: "",
          id: 0,
        },
        {
          name: "end_date",
          type: "Data",
          values: "",
          id: 0,
        },
        {
          name: "email",
          type: "texto",
          values: "",
          id: 1,
        },
        {
          name: "nome_template",
          type: "texto",
          values: "",
          id: 2,
        },
        {
          name: "celular",
          type: "texto",
          values: "",
          id: 3,
        },
        {
          name: "tipo_envio",
          type: "texto",
          values: "sms, email",
          id: 4,
        },
        {
          name: "evento",
          type: "texto",
          values: "sent, opened, clicked, bounced",
          id: 5,
        },
        {
          name: "page",
          type: "Numeric",
          values: "1",
          id: 5,
        },
      ],
      dataTypes: {
        integer: "int",
        string: "texto",
        datetime: "Data Hora",
        date: "Data",
        decimal: "Decimal",
        bool: "Booleano",
      },
      dataExamples: {
        integer: "12345",
        string: "Text Field",
        datetime: "2023-02-01 20:26:04.000",
        date: "2023-02-01",
        decimal: "4,5",
        bool: "true",
      },
      tabs: [
        // { id: "primeira", text: "PYTHON" },
        // { id: "segunda", text: "NODE.JS" },
        { id: "terceira", text: "CURL" },
      ],
    };
  },
  async created() {
    this.getToken();
  },
  computed: {},
  methods: {
    async getToken() {
      const response = await this.http.get("exportDataClosed/credentials");
      console.log(response);

      this.token = response.apiToken;

        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-1;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: $z-s-1;

      & > div {
        flex: 1 1 400px;
        // border: 2px solid red;
        padding: 0.5rem;
        // width: 100%;
        // border: 1px solid red;
      }

      &__left {
        .card-key {
          background: #ececec;
          border-radius: 15px;
          padding: $z-s-1 1.5rem;
          &__line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              cursor: pointer;
            }
          }
        }
        .card-resource {
          .link-resource {
            font-size: 0.95rem;

            @media (max-width: 750px) {
              font-size: 0.75rem;
            }
          }
        }

        .card-parameters {
          &__table {
            height: 35vh;
            overflow-y: auto;
            @include trackScrollBar;
          }
        }
        & > div {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
      &__right {
        .tabs {
          .curl {
            margin-top: 0.5rem;
            background: #272822;
            padding: 0.8rem 0.5rem;
            color: white;
            font-size: 0.7rem;
            border-radius: 10px;
          }
        }
      }
    }

    .title-item {
      font-size: 1.25rem;
      font-weight: 600;
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
